import {useParams, useLocation} from "react-router-dom";
import '../styles/Common.css';
import '../styles/About.css';
import {useTranslation, Trans} from "react-i18next";
import {Helmet} from 'react-helmet-async';
import SEO from "./SEO";
import heroBackgroundImage from '../assets/images/hero_background/about_hero_bg_original4.jpg';
import React from "react";


const Article = () => {
    const {t, i18n} = useTranslation(['about', 'common', 'title', 'home'])
    const isChinese = i18n.language === 'zh';

    const {slug} = useParams(); // Get the slug from the URL
    console.log("slug:", slug);

    /*  const location = useLocation();
      const slug = location.pathname.split('/').pop(); // Extract the last part of the pathname
      console.log("slug:", slug);*/

    // Fetch all news articles
    const articles = t('news', {ns: 'articles', returnObjects: true});
    console.log("articles:", articles);
    // Find the article that matches the slug
    let article = articles.find(item => item.teaser_url === `/articles/${slug}`);
    // const article = articles[0];
    console.log("article:", article);
    if (!article) {
        article = articles[0];
        console.log("warning: article not found, using first article instead");
    }

    if (!article) {
        return <div>Article not found</div>;
    }

    return (
        <div className={"bg-white"}>
            <SEO title={article && article.content_title}></SEO>
            <div className={"bg-cover bg-white bg-blend-hard-light bg-bottom bg-no-repeat min-h-[350px] sm:min-h-[400px] w-full flex flex-col justify-start items-center p-6"}
                 style={{backgroundImage: `url(${heroBackgroundImage})`}}
            >
                <div className={"flex flex-col justify-center items-start max-w-[900px] mt-36 mb-16 md:mr-48"}>
                    <div className={` font-bold tracking-[.1em] text-white/90 leading-normal ${isChinese ? 'text-4xl sm:text-[3.5rem] sm:tracking-[.05em]' : 'text-4xl sm:text-[3rem] sm:tracking-[.03em]'}`}>
                        {article && article.content_title}
                    </div>
                </div>
            </div>
            <div className={"content-container py-12"}>
                <div className={"flex flex-wrap justify-evenly items-start max-w-8xl mx-auto px-6 sm:px-12 pb-36 rounded-3xl bg-cover bg-white bg-blend-multiply bg-center-top bg-no-repeat"}>
                    <div className={"lg:max-w-[50%]"}>
                        <p className={"text-neutral-600 text-normal font-light font-sans mb-4"}>{new Date(article.date).toLocaleDateString()}</p>
                        {article && article.content.map((item, index) => {
                            if (item.src) {
                                return (
                                    <div key={index} className="mt-10 mb-8">
                                        <img src={process.env.PUBLIC_URL + `/images${article.teaser_url}/${item.src}`} alt={item.caption}
                                             className="mb-4 w-full h-auto rounded-lg object-contain"/>
                                        <div className={"text-neutral-600 text-sm font-light font-sans text-center"}>{item.caption}</div>
                                    </div>
                                );
                            }
                            if (item.text) {
                                return <p key={index} className={"text-neutral-600 text-xl font-light font-sans leading-loose"}>{item.text}</p>;
                            }
                            return null;
                        })}

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Article;
